import { Button, Stack, Text } from '@kolonialno/lama';
import { Link as ReactRouterLink } from 'react-router';

import Container from '../components/Container';

function NoMatch() {
    return (
        <Container title="Page not found">
            <Text variant="body1" className="purple-text">
                The requested page could not be found.
            </Text>

            <Stack>
                <Button
                    variant="primary"
                    size="m"
                    as={ReactRouterLink}
                    to="/authentication/"
                >
                    Log in
                </Button>
            </Stack>
        </Container>
    );
}

export default NoMatch;
