import '@kolonialno/lama/dist/styles.css';
// eslint-disable-next-line
import 'vite/modulepreload-polyfill';

import './styles.scss';

import { LamaProvider } from '@kolonialno/lama';
import {
    captureConsoleIntegration,
    init,
    makeBrowserOfflineTransport,
    makeFetchTransport,
    reactRouterV6BrowserTracingIntegration,
    replayIntegration,
    setUser,
} from '@sentry/react';
import { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router';

import App from './AuthenticationApp/App';

type SentryContext = {
    SENTRY_DSN: string | null;
    ENVIRONMENT: string;
    VERSION: string;
    USER_ID: string | null;
    USER_EMAIL: string | null;
};

const sentryContext: SentryContext = JSON.parse(
    document.getElementById('sentry-context')?.textContent || '',
);

let integrations: any[] = [
    captureConsoleIntegration({
        levels: ['error'],
    }),
    reactRouterV6BrowserTracingIntegration({
        useEffect: useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
    }),
];

const SENTRY_REPLAY_ENABLED = false;

if (SENTRY_REPLAY_ENABLED) {
    integrations = [
        ...integrations,
        replayIntegration({
            maskAllText: true,
            blockAllMedia: true,
        }),
    ];
}

init({
    dsn: sentryContext.SENTRY_DSN || undefined,
    transport: makeBrowserOfflineTransport(makeFetchTransport),
    environment: sentryContext.ENVIRONMENT,
    release: sentryContext.VERSION,
    attachStacktrace: true,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations,
    tracesSampleRate: 0,
});

setUser({
    id: sentryContext.USER_ID || undefined,
    email: sentryContext.USER_EMAIL || undefined,
});

createRoot(document.getElementById('root')!).render(
    <StrictMode>
        <LamaProvider>
            <App />
        </LamaProvider>
    </StrictMode>,
);
