import './styles.scss';

import {
    Button,
    FormControl,
    Input,
    Link,
    Message,
    Spacer,
    Stack,
} from '@kolonialno/lama';
import { useRef, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router';

import { useCSRFToken } from '../../../utils/csrf';
import { useFormErrors } from '../../../utils/viewArgs';
import Container from '../../components/Container';
import { useButtonLoading } from '../../utils/button-state';
import { useAuthenticationContext } from '../../utils/context';

export function RequestOTC() {
    const { disabled, loadingState, setLoading } = useButtonLoading();
    const csrf = useCSRFToken();
    const context = useAuthenticationContext();
    const formErrors = useFormErrors();

    const hasErrors = Object.keys(formErrors).length > 0;

    const [countryCode, setCountryCode] = useState<string>('+47');
    const countryCodeRef = useRef<HTMLInputElement>(null);

    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const phoneNumberRef = useRef<HTMLInputElement>(null);

    return (
        <Container title="Login portal">
            {hasErrors && (
                <Message variant="danger">
                    Country code and phone number is required
                </Message>
            )}
            <Stack>
                <form
                    method="post"
                    onSubmit={() => {
                        setLoading();
                    }}
                >
                    <input
                        type="hidden"
                        name="csrfmiddlewaretoken"
                        value={csrf}
                    />
                    <input
                        type="hidden"
                        name="rd"
                        value={context.redirect || undefined}
                    />

                    <Stack spacing="xs">
                        <Stack direction="horizontal" alignItems="flex-start">
                            <FormControl
                                name="country_code"
                                label="Country code"
                                className="form-control country-code"
                            >
                                <Input
                                    ref={countryCodeRef}
                                    onChange={({ target }) =>
                                        setCountryCode(target.value)
                                    }
                                    value={countryCode}
                                    required
                                    autoCapitalize="none"
                                    maxLength={4}
                                    autoComplete="tel-country-code"
                                />
                            </FormControl>
                            <FormControl
                                name="phone_number"
                                label="Phone number"
                                className="form-control"
                            >
                                <Input
                                    ref={phoneNumberRef}
                                    onChange={({ target }) =>
                                        setPhoneNumber(target.value)
                                    }
                                    autoFocus
                                    value={phoneNumber}
                                    type="tel"
                                    required
                                    maxLength={16}
                                    autoComplete="tel-national"
                                />
                            </FormControl>
                        </Stack>

                        <Spacer spacing="xs" />

                        <Button
                            type="submit"
                            variant="primary"
                            size="m"
                            disabled={disabled}
                            loadingState={loadingState}
                        >
                            Send SMS code
                        </Button>
                    </Stack>
                </form>
            </Stack>
            <Link
                as={ReactRouterLink}
                to={`/authentication/?rd=${context.redirect}`}
                className="purple-text"
            >
                Back
            </Link>
        </Container>
    );
}
