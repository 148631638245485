import { Button, Stack } from '@kolonialno/lama';
import { Link as ReactRouterLink } from 'react-router';

import Container from '../components/Container';
import { useAuthenticationContext } from '../utils/context';

function Index() {
    const context = useAuthenticationContext();

    return (
        <Container title="Login portal">
            <Stack>
                <Button
                    id="okta"
                    variant="primary"
                    size="m"
                    as="a"
                    href={`${context.samlLoginUrl}?rd=${context.redirect}`}
                >
                    Log in with Okta
                </Button>

                {context.deviceAuthenticationAllowed && (
                    <Button
                        id="device"
                        variant="primary"
                        size="m"
                        as={ReactRouterLink}
                        to={`/authentication/device/?rd=${context.redirect}`}
                    >
                        Log in as device
                    </Button>
                )}

                <Button
                    id="login"
                    variant="primary"
                    size="m"
                    as={ReactRouterLink}
                    to={`/authentication/login/?rd=${context.redirect}`}
                >
                    Log in with email
                </Button>

                <Button
                    id="otc"
                    variant="primary"
                    size="m"
                    as={ReactRouterLink}
                    to={`/authentication/sms/?rd=${context.redirect}`}
                >
                    Log in with SMS
                </Button>
            </Stack>
        </Container>
    );
}

export default Index;
