import { Button, Link, Message, Stack } from '@kolonialno/lama';
import { useEffect, useRef } from 'react';
import { Link as ReactRouterLink } from 'react-router';

import { useCSRFToken } from '../../utils/csrf';
import Container from '../components/Container';
import { useButtonLoading } from '../utils/button-state';
import { useAuthenticationContext } from '../utils/context';

function DeviceLoginNotAllowed() {
    return <Message variant="danger">Device login is not permitted.</Message>;
}

function Device() {
    const { disabled, loadingState, setLoading } = useButtonLoading();
    const loginButton = useRef<HTMLButtonElement>(null);

    const csrf = useCSRFToken();
    const context = useAuthenticationContext();

    useEffect(() => {
        if (
            !disabled &&
            context.deviceAuthenticationAllowed &&
            context.deviceAccounts.length === 1 &&
            loginButton.current
        ) {
            loginButton.current.click();
        }
    }, [
        disabled,
        loginButton,
        context.deviceAuthenticationAllowed,
        context.deviceAccounts,
    ]);

    return (
        <Container title="Login portal">
            {!context.deviceAuthenticationAllowed && <DeviceLoginNotAllowed />}

            {context.deviceAuthenticationAllowed && (
                <Stack direction="vertical">
                    {context.deviceAccounts.map((account, i) => (
                        <form
                            key={account.email}
                            method="post"
                            action="/authentication/login/"
                            onSubmit={() => {
                                setLoading();
                            }}
                        >
                            <input
                                type="hidden"
                                name="csrfmiddlewaretoken"
                                value={csrf}
                            />
                            <input
                                type="hidden"
                                name="rd"
                                value={context.redirect || undefined}
                            />
                            <input
                                type="hidden"
                                name="username"
                                value={account.email}
                            />
                            <input
                                type="hidden"
                                name="password"
                                value="device-account"
                            />
                            <Stack>
                                <Button
                                    variant="primary"
                                    size="m"
                                    type="submit"
                                    ref={i === 0 ? loginButton : null}
                                    disabled={disabled}
                                    loadingState={loadingState}
                                >
                                    {account.name}
                                </Button>
                            </Stack>
                        </form>
                    ))}
                </Stack>
            )}

            <Link
                as={ReactRouterLink}
                to={`/authentication/?rd=${context.redirect}`}
                className="purple-text"
            >
                Back
            </Link>
        </Container>
    );
}

export default Device;
